* {
  box-sizing: border-box;
}

html {
  background-image: linear-gradient(-180deg, #121212 7%, #0C0C0C 99%);
  color: #fff;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: initial !important;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  line-height: 1;
  font-weight: 300;
}

h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* mapbox styles */
.mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
